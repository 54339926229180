import  { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
// At the top of your index.js file
if (typeof process === 'undefined') {
  global.process = {
    env: { NODE_ENV: 'production' },
    cwd: () => '/'
  };
}

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))
const ResetPassword = lazy(() => import('./pages/ResetPassword')) 


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()
 


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(true)
  }, [])


  return (
  //   <NovuProvider
  //   subscriberId={"65ed01770bc8809a2d90d242"}
  //   applicationIdentifier={"k80O6pCzO2he"}
  // >
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
    // </NovuProvider>
  )
}

export default App
