import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import  convertDateFormat   from "../../../utils/datefunction";
const date = new Date();
date.setMonth(date.getMonth() - 1);
const oneMonthAgo = date.toISOString().split("T")[0];

const initialState = {
  activeUsers: null,
  newUsers: 0,
  shiftedActiveUsers: 0,
  shiftedNewUsers: 0,
  activeCities: [],
  activeUserByCity: [],
  newUserByCity: [],
  transactionsVolume: 0,
  shiftedTransactionsVolume: 0,
  startDate: oneMonthAgo,
  newUserRange: [],
  activeUserRange: [],
  endDate: new Date().toISOString().split("T")[0],
  error: null,
  pending: false,
};

export const fetchAnalytics = createAsyncThunk(
  "analytics/fetchAnalytics",
  async ({ rejectWithValue, startDate, endDate }) => {
    try {
      const response = await axios.post("api/v1/active-users", {
        startDate,
        endDate,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMontlyAnalytics = createAsyncThunk(
  "analytics/fetchMontlyAnalytics",
  async (_, { rejectWithValue }) => {
    console.log("intital state");
    try {
      const response = await axios.get("/api/v1/monthly-active-users");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsersByCity = createAsyncThunk(
  "analytics/fetchUsersByCity",
  async ({ rejectWithValue, startDate, endDate }) => {
    
    try {
      const response = await axios.post("/api/v1/active-users-by-city", {
        startDate,
        endDate,
      });
      console.log("active users by city",response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchshiftedTransByVolum = createAsyncThunk(
  "analytics/fetchshiftedTransByVolum",
  async ({ rejectWithValue, startDate, endDate }) => {

    const start =  await convertDateFormat(startDate);
    const end =await convertDateFormat(endDate);
    console.log("here is the params", start, endDate  )
    try {
      const response = await axios.get("/api/transactions_by_period", {
        params: {
          "start_date": start,
          "end_date":
          end,
        },
        timeout: 30000,
      });
      console.log("here is the params", startDate, endDate  )
      console.log("transactions by period", response.data);
      return response.data;
    }  
    catch (error) {
      console.log("transaction by perio erro", error);  
      return rejectWithValue(error.response.data);
    }
  }
);



export const fetchtransVolByPeriod = createAsyncThunk(
  "analytics/fetchtransVolByPeriod",
  async ({ rejectWithValue, startDate, endDate }) => {

    const start =  await convertDateFormat(startDate);
    const end =await convertDateFormat(endDate);
    console.log("here is the params", start, endDate  )
    try {
      const response = await axios.get("/api/transactions_by_period", {
        params: {
          "start_date": start,
          "end_date":
          end,
        },
        timeout: 30000,
      });
      console.log("here is the params", startDate, endDate  )
      console.log("transactions by period", response.data);
      return response.data;
    }  
    catch (error) {
      console.log("transaction by perio erro", error);  
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePeriod = createAction("analytics/updatePeriod");

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.pending, (state) => {
        state.activeUsers = null;
        state.newUsers = null;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        let activeUsers = 0;
        let newUsers = 0;
        let shiftedActiveUsers = 0;
        let shiftedNewUsers = 0;

        const rows = action.payload.rows;

        rows.forEach((row) => {
          const dateRange = row.dimensionValues[0].value;
          const activeUsersValue = parseInt(row.metricValues[0].value);
          const newUsersValue = parseInt(row.metricValues[1].value);

          if (dateRange === "date_range_0") {
            activeUsers = activeUsersValue;
            newUsers = newUsersValue;
          } else if (dateRange === "date_range_1") {
            shiftedActiveUsers = activeUsersValue;
            shiftedNewUsers = newUsersValue;
          }
        });

        state.activeUsers = activeUsers;
        state.newUsers = newUsers;
        state.shiftedActiveUsers = shiftedActiveUsers;
        state.shiftedNewUsers = shiftedNewUsers;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.activeUsers = null;
        state.newUsers = null;
        state.error = action.payload;
      })

      .addCase(fetchMontlyAnalytics.fulfilled, (state, action) => {
        console.log(action.payload);
        let activeUserRange=[0,0,0,0];
        let newUserRange=[0,0,0,0];

        const rows = action.payload.rows;
        console.log("rows", rows);
        rows.forEach((row) => {
          const dateRange = row.dimensionValues[0].value;
          const activeUsersValue = parseInt(row.metricValues[0].value);
          const newUsersValue = parseInt(row.metricValues[1].value);
          if (dateRange === "date_range_0") {
            activeUserRange[3] = activeUsersValue;
            newUserRange[3] = newUsersValue;
          }
          else if (dateRange === "date_range_1") {
            activeUserRange[2] = activeUsersValue;
            newUserRange[2] = newUsersValue;
          } 
          else if (dateRange === "date_range_2") {
            activeUserRange[1] = activeUsersValue;
            newUserRange[1] = newUsersValue;
          }
          else if (dateRange === "date_range_3") {
            activeUserRange[0] = activeUsersValue;
            newUserRange[0] = newUsersValue;
          }
 
        });
        state.activeUserRange = activeUserRange;
        state.newUserRange = newUserRange;
        console.log("state", activeUserRange); 
      })
      .addCase(updatePeriod, (state, action) => {
        console.log("this is the action", action.payload);
        console.log("this is the state", state.startDate);
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
        console.log("this is the state", state.startDate);
      }) 

      .addCase(fetchUsersByCity.fulfilled, ( state, action) => {
        const activeUserByCity = [];
        const newUserByCity = [];
        const activeCities = [];
        const rows = action.payload.rows;
        console.log("active users by city", rows);
        rows.forEach((row) => {
          const city = row.dimensionValues[0].value;
          console.log("city", city);
          const activeUsers = parseInt(row.metricValues[0].value);
          console.log("active users", activeUsers);
          const newUsers = parseInt(row.metricValues[1].value);
          console.log("new users", newUsers);
          activeUserByCity.push({ city, activeUsers });
          newUserByCity.push({ city, newUsers });
          activeCities.push(city);
          

        });
        console.log("active user by city", activeUserByCity);
        console.log("new user by city", newUserByCity);
        console.log("active cities", activeCities);
        state.activeUserByCity = activeUserByCity;
        state.newUserByCity = newUserByCity;
        state.activeCities = activeCities;
      } )
.addCase(fetchtransVolByPeriod.fulfilled, (state, action) => {
  console.log("here is the action", action.payload.amount);
        state.transactionsVolume = action.payload.amount 
      }
      )
      .addCase(fetchshiftedTransByVolum.fulfilled, (state, action) => {
        state.shiftedTransactionsVolume = action.payload.amount; 
      }
      )
  },
});

export default analyticsSlice.reducer;
