import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import transactionFeeReducer from '../features/fees/components/transactionFeeSlice'
import usersReducer from '../features/user/components/usersSlice'
import walletStatsReducer from '../features/wallet/components/walletStatsSlice'
import analyticsReducer from '../features/analytics/components/analyticsSlice'
import transactionsReducer from '../features/transactions/components/transactionsSlice'
import teamReducer from '../features/settings/team/components/teamSlice'
const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  transactionFee: transactionFeeReducer ,
  userList: usersReducer,
  walletStats: walletStatsReducer,
  analytics: analyticsReducer,
  transactions: transactionsReducer,
  team: teamReducer

}

export default configureStore({
    reducer: combinedReducer
})