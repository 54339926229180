import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { loginUser, sendPasswordReset, resetPassword } from "./authService";

const initialState = {
  admin: [],
  data: [],
  hasMore: false,
  loading: false,
  singleUserLoading: false,
  singleUserError: null,
  banuserLoading: false,
  banuserError: null,
  ban: false,
  error: null,
  offset: 0,
  searchText: "",
  user: [],
};

// Async action to fetch the users from the database
export const fetchUsers = createAsyncThunk(
  "userList/fetchUsers",
  async ({ offset, limit }) => {
    console.log("fetching users one", offset, limit);
    try {
      const response = await axios.get("/api/v1/get_users", {
        params: {
          offset,
          limit,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "userList/fetchUser",
  async ({ id }) => {
    console.log("fetching users one", id);
    try {
      const response = await axios.get(`/api/users/${id}`, {});
      console.log(response.data.user);
      return response.data.user;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const searchUsers = createAsyncThunk(
  "userList/searchUsers",
  async ({ offset, limit, query }) => {
    try {
      const response = await axios.get("/api/v1/research_user", {
        params: {
          offset,
          limit,
          query,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getSavedAdmin = createAsyncThunk(
  "userList/getSavedAdmin",
  async () => {
    const admin = await localStorage.getItem("admin");

    return JSON.parse(admin);
  }
);

export const changeUserStatus = createAsyncThunk(
  "userList/changeUserStatus",
  async ({ id, status }) => {
    try {
      const response = await axios.post("/api/v1/banne_user", {
        user_id: id,
      });

      return response.status;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "userList/deleteUser",
  async ({ id }) => {
    try {
      console.log("deleting in redux user", id);
      const response = await axios.post("/api/users/delete", {
        user_id: id,
      });
      console.log(response);
      return response.status;
    } catch (error) {
      console.log(error.message);
      return Promise.reject(error);
    }
  }
);

export const incrementOffset = createAction("userList/incrementOffset");
export const decrementOffset = createAction("userList/decrementOffset");
export const setSearchText = createAction("userList/setSearchText");
export const removeSearchText = createAction("userList/removeSearchText");
export const clearState = createAction("userList/clearState");

const usersSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.searchText = "";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload.users;
        state.hasMore = action.payload.hasMore;
        state.searchText = "";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.searchText = "";
      })
      .addCase(searchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.hasMore = action.payload.hasMore;
        state.data = action.payload.response;
        state.offset = 0;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(incrementOffset, (state) => {
        state.offset += 1;
      })
      .addCase(decrementOffset, (state) => {
        state.offset -= 1;
      })
      .addCase(setSearchText, (state, action) => {
        console.log("here to add new search text", action.payload.searchText);
        state.searchText = action.payload;
      })

      .addCase(removeSearchText, (state) => {
        console.log("here to remove search text", state.searchText);
        state.searchText = "";
      })

      .addCase(getSavedAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSavedAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.admin = action.payload;
      })
      .addCase(getSavedAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchUser.pending, (state) => {
        state.singleUserLoading = true;
        state.singleUserError = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.singleUserLoading = false;
        state.singleUserError = null;
        state.user = action.payload;
      })

      .addCase(fetchUser.rejected, (state, action) => {
        state.singleUserLoading = false;
        state.singleUserError = action.error.message;
      })

      .addCase(changeUserStatus.pending, (state) => {
        state.banuserLoading = true;
        state.error = null;
      })
      .addCase(changeUserStatus.fulfilled, (state) => {
        state.banuserLoading = false;
        state.banuserError = null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deletUserPending = true;
        state.deleUserError = null;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deletUserPending = false;
        state.deleUserError = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deletUserPending = false;
        let errorMessage = "An error occurred";

        if (action.error.message) {
          const errorStatusMatch = action.error.message.match(/\b\d{3}\b/);
          const errorStatus = errorStatusMatch
            ? parseInt(errorStatusMatch[0])
            : null;

          switch (errorStatus) {
            case 400:
              errorMessage = "Bad Request";
              break;
            case 401:
              errorMessage = "Unauthorized";
              break;
            case 403:
              errorMessage = "Forbidden";
              break;
            case 404:
              errorMessage = "Not Found";
              break;
            case 500:
              errorMessage = "Internal Server Error";
              break;
              case 415:
                errorMessage = "Internal Server Error";
                break;
            default:
              errorMessage = action.error.message;
          }
        }

        state.deleUserError = errorMessage;
      })
      
      .addCase(clearState, (state) => {
        
      
        state.deletUserPending = false;
        state.deleUserError = null;
        
      ;
  }) ;
      
      
  },
});

export default usersSlice.reducer;
