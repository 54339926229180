import axios from "axios"
import { send } from "process";
import { useSelector, useDispatch } from "react-redux";
import { showNotification } from "../features/common/headerSlice";


const initializeApp = () => {
     
    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    axios.defaults.timeout = process.env.REACT_APP_AXIOS_TIMEOUT || 5000;

    console.log("Base URL set to: ", process.env.REACT_APP_API_URL)
    const cId=process.env.REACT_APP_GA_CLIENT_ID;
    console.log("ClientId",cId);
    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 401) {
             // Clear local storage
             localStorage.clear();
                     // Dispatch a notification
              
                     window.location.href = '/login';
             
            return axios(error.config);  
          }
          return Promise.reject(error);  
        }
      );

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code



    } else {
        // Prod build code



        // Removing console.log from prod
        console.log = () => {};


        // init analytics here
    }

}

export default initializeApp


