import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  admin : [],
  members: [],

  loading: false,
  error: null,
  deleteLoading: false,
  deleteError: null,
  getAdminLoading: false,
  getAdminError: null,
};
// Async action to fetch the users from the database
export const fetchAdmins = createAsyncThunk(
  "team/fetchAdmins",
  async () => {
    console.log("fetching admins one");
    try {
      const response = await axios.get("/api/v1/get_all_admin", {});
      console.log("feteched admins two", response.data);
      return response.data;
    } catch (error) {
      console.log("error fetching admins", error);
      return Promise.reject(error);
    }
  }
);
export const deleAdmin = createAsyncThunk(
  "team/deleteAdmin",
  async (id) => {
    console.log("fetching admins one");
    try {
      const response = await axios.delete("/api/v1/dele_admin", {
        data: { id }, 
      });
      console.log("feteched admins two", response.data);
      return response.data;
    } catch (error) {
      console.log("error fetching admins", error);
      return Promise.reject(error);
    }
  }
);

 export const getAdmin = createAsyncThunk(
  "team/adminSlice",
  async ( ) => { 
    try {
      const adminData = JSON.parse(localStorage.getItem('admin'));
      console.log("fetched admins from local storage", adminData);
      return adminData;
    } catch (error) {
      console.log("error fetching admins from local storage", error);
      return Promise.reject(error);
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.members = action.payload.response;
        state.loading = false;
        state.error = null;
        console.log("fetched admins", action.payload);
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  .addCase(deleAdmin.pending, (state) => {
        state.deleteLoading = true;
        state.deleteError = null;
      }
      )
      .addCase(deleAdmin.fulfilled, (state, action) => {
        //state.members = state.members.filter((member) => member.id !== action.payload.id);
        state.deleteLoading = false;
        state.deleteError = null;
      })
      .addCase(deleAdmin.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteError = action.error.message;
      })
      .addCase(getAdmin.pending, (state) => {
        state.getAdminLoading = true;
        state.getAdminError = null;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.admin = action.payload;
        state.getAdminLoading = false;
        state.getAdminError = null;
      })
      .addCase(getAdmin.rejected, (state, action) => {
        state.getAdminLoading = false;
        state.getAdminError = action.error.message;
      })
   
      
      
      ;
  },
});

export default teamSlice.reducer;
