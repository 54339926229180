import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Async action to fetch the transaction fees from the database
export const fetchTransactionFees = createAsyncThunk(
  'transactionFee/fetchTransactionFees',
  async () => {
    try {
      const response = await axios.get('/api/v1/get_fees', { timeout: 5000 });
      console.log('response', response.data.response);
      return response.data.response;
    } catch (error) {
      console.log('Error fetching transaction fees: ', error);
      return Promise.reject(error);
    }
  }
);

// Async action to update a transaction fee
export const updateTransactionFee = createAsyncThunk(
  'transactionFee/updateTransactionFee',
  async () => {
    try {
      const response = await axios.get('/api/v1/get_fees');
      console.log('response', response.data.response);
      return response.data.response;
    } catch (error) {
      console.log('Error fetching transaction fees: ', error);
      return Promise.reject(error);
    }
  }
);

const transactionFeeSlice = createSlice({
  name: 'transactionFee',
  initialState ,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionFees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionFees.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTransactionFees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTransactionFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTransactionFee.fulfilled, (state, action) => {
        // Update logic as before
       
          state.data  = action.payload;
    
        state.loading = false;
      })
      .addCase(updateTransactionFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default transactionFeeSlice.reducer;
