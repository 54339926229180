import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios"; 

const initialState = {
  transactionsAnalytics: 0,
  shiftedTransactionsAnalytics: 0,
  monthlyTransactions: [],
  transactions: [],
  error: null,
  pending: false,
  hasMore: false,
  loading: false,
  offset: 0,
};

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async ({ rejectWithValue, startDate, endDate }) => {
    
    try {
      const response = await axios.post("/api/v1/transactions",
        {startDate,
          endDate,
        }
      );
       
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTransactions = createAsyncThunk(
  "transactions/getTransactions",
  async ({ rejectWithValue, offset ,limit }) => {
    
    try {
      const response = await axios.get('/api/all_transactions', {
        params: {
          offset,
          limit,
        },
      });
       console.log("response",response.data)
       console.log(
        "offset",offset,
       )
       console.log("limit",limit)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

 

export const fetchMonthlyTransactions = createAsyncThunk(
  "transactions/fetchMonthlyTransactions",
  async (_, { rejectWithValue }) => {
     
    try {
      const response = await axios.get("/api/v1/monthly-transactions");
       
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const incrementOffset=createAction("transactions/incrementOffset");
export const decrementOffset=createAction("transactions/decrementOffset");
export const resetOffset=createAction("transactions/resetOffset");
const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.fulfilled, (state, action) => {
      let transactionsAnalytics = 0;
      let shiftedTransactionsAnalytics = 0;
      const rows=action.payload.rows;
        
        rows.forEach((row) => {
            const dateRange = row.dimensionValues[1].value;
          const value =parseInt(row.metricValues[0].value);
          if (dateRange === "date_range_0") {
            transactionsAnalytics = value;
          } else if (dateRange === "date_range_1") {
            shiftedTransactionsAnalytics = value;
          }


        });
        state.transactionsAnalytics = transactionsAnalytics;
        state.shiftedTransactionsAnalytics = shiftedTransactionsAnalytics;
       
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.error = action.payload;
      })
       
      
      .addCase(fetchMonthlyTransactions.fulfilled, (state, action) => {
        let monthlyTransactions = [0,0,0,0];
        const rows = action.payload.rows;
        console.log("monthly rows ",rows);
        rows.forEach((row) => {
          const dateRange = row.dimensionValues[1].value;
          const value = parseInt(row.metricValues[0].value);
          if (dateRange === "date_range_0") {
            monthlyTransactions[3] = value;
          }
          else if (dateRange === "date_range_1") {
            monthlyTransactions[2] = value;
          }
          else if (dateRange === "date_range_2") {
            monthlyTransactions[1] = value;
          }
          else if (dateRange === "date_range_3") {
            monthlyTransactions[0] = value;
          }

        });
        state.monthlyTransactions = monthlyTransactions;




      })
      .addCase(fetchMonthlyTransactions.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getTransactions.pending, (state) => {

        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload.transactions;
        state.hasMore = action.payload.hasMore;
      }
      )  
      .addCase(getTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(incrementOffset, (state) => {
        state.offset += 1;
      })
      .addCase(decrementOffset, (state) => {
        state.offset -= 1;
      })
      
      .addCase(resetOffset, (state) => {
        state.offset = 0;
      }
      )
      
      
      
      
      
      
      ;
  },
});

export default transactionsSlice.reducer;