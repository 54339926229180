 import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
 import axios from 'axios';


 const initialState = { 
        data: [],
        flutterwave: [],
        balance: [],
        loading: false,
        error: null,
    };


export const fetchWalletStats = createAsyncThunk(   
    'walletStats/fetchWalletStats', 
    async () => {
        try {
            const response = await axios.get('/api/v1/admin_banlance');
            console.log(response.data.response)
            return response.data.response;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

 export const fetchFlutterwaveStats = createAsyncThunk(
    'walletStats/fetchFlutterwaveStats',
    async () => {
        try {
            const response = await axios.get('/api/v1/flutterwave_balance');
            console.log(response.data)
            return response.data ;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const fetchBalance = createAsyncThunk(
    'walletStats/fetchBalance',
    async () => {
        try {
            const response = await axios.get('/api/wallet/get_global_balance');
            console.log(response.data)
            return response.data ;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

const walletStatsSlice = createSlice({
    name: 'walletStats',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWalletStats.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWalletStats.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchWalletStats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }) 

      
            .addCase(fetchFlutterwaveStats.fulfilled, (state, action) => {
                  
                state.flutterwave = action.payload;
            })

            .addCase(fetchBalance.fulfilled, (state, action) => {
                state.balance = action.payload.response;
            })
    },
});

export default walletStatsSlice.reducer;